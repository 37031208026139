﻿.form-group {
    margin-bottom: 32px;
}

.form-group label {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 16px;
}

.form-control {
    padding: 12px 14px;
    border-radius: 8px;
    height: auto;
    bckground-color: #FFFFFF;
    border-color: #353636;
    font-family: 'DM Sans', sans-serif;
}

input {
    font-family: 'DM Sans', sans-serif;
}

.input-group-text {
    border-radius: 8px;
    border-color: #353636;
}

.action-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
}


/* validator */

.validation-summary-errors {
//    display: none;
}

.input-validation-error label {
    color: $danger;
}

.form-control.input-validation-error {
    border-color: $danger;
    border-width: 2px;
}

.text-danger.field-validation-error {
    margin-left: 14px;
    margin-right: 14px;
    font-size: 0.75rem;
    margin-top: 3px;
    line-height: 1.66;
    display: block;
}
