﻿body {
    color: #353636;
    margin: 0;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.43;
}

.navbar {
    min-height: 100px;
    border: none;
    box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
}

.body-container {
    margin-top: 60px;
    padding-bottom: 60px;
}


@include media-breakpoint-down(sm) {
    .navbar {
        min-height: 67px;
    }

    .header-logo {
        height: 30px;
    }
}

@include media-breakpoint-up(md) {
    .paper-container {
        display: flex;
        flex-wrap: wrap;
        width: calc(100% + 32px);
    }

    .paper-item {
        flex-grow: 0;
        max-width: 33.333333%;
        flex-basis: 33.333333%;
    }
}

.paper-container {
    margin: 1rem -16px;
}

.paper-item {
    padding: 16px;
}

.paper {
    border-top: 10px solid #23A499;
    box-shadow: 0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);
    border-radius: 8px;
    color: #353636;
    background-color: #fff;
    height: 100%;
}

.paper-inner {
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    padding: 24px;
}

.paper p {
    font-size: 1.25rem;
    margin-bottom: 1rem;
}

.paper .action-group {
    border-top: 1px solid #eff0f0;
    padding-top: 1rem;
}

/* from the original site.scss file */


.welcome-page {
    li {
        list-style: none;
        padding: 4px;
    }
}

.logged-out-page {
    iframe {
        display: none;
        width: 0;
        height: 0;
    }
}

.grants-page {
    .card {
        margin-top: 20px;
        border-bottom: 1px solid lightgray;

        .card-title {
            img {
                width: 100px;
                height: 100px;
            }

            font-size: 120%;
            font-weight: bold;
        }

        label {
            font-weight: bold;
        }
    }
}
