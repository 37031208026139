﻿.footer {
    padding-top: 68px;
    padding-bottom: 32px;
    background-color: #023A70;
    color: white;
}

.footer-space {
    padding: 12px 0;
}

.footer-address {
    margin-bottom: 24px;
}

.footer-link {
    text-decoration: underline;
    color: $white;

    &:hover {
        color: $white;
    }
}

.footer-divider {
    background-color: rgb(52, 97, 140);
    margin-bottom: 32px;
    margin-top: 32px;
}
