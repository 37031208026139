﻿

.btn {
    padding: 11px 16px;
    min-width: 150px;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    line-height: 1.75;
    text-transform: none;
    border: 0;
    margin: 0;
    user-select: none;
    display: inline-flex;
    vertical-align: middle;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
    box-shadow: none;
    font-size: 1rem;
}
